<script lang="ts">
import { v4 as uuid_v4 } from 'uuid';
import { onMount, onDestroy } from 'svelte'
import Modal from '@/components/Modals/Modal.svelte'
import { getString } from '@/misc/utilities'
import type { Events, ModalFnParams } from '@/components/Modals/types'

const EVENTS = {
    MODAL_OPEN: 'MODAL_OPEN',
    MODAL_CLOSE: 'MODAL_CLOSE',
    MODAL_LOCK: 'MODAL_LOCK',
    MODAL_UNLOCK: 'MODAL_UNLOCK',
}

const sizePresets = {
}

type ModalTypes = 'actionlist'|'confirm'|'component'|'element'|'form'|'html'|'video'

export let events: Events
let modals = []
let modalRefs = {}
let scrollbarIsSet = false

function updateModalRef(id, bindings) {
    modalRefs[id] = bindings
}

function closeModal(id: string, confirm = false) {
    const modal = getModal(id)

    if (modal?.locked && !confirm) {
        createModal({
            title: getString('confirm'),
            content: typeof modal.locked === 'string' ? modal.locked : getString('areyousure'),
            type: 'confirm',
            actions: [
                { name: 'confirm', style: 'danger',          text: getString('confirm'), fn: (modal: ModalFnParams) => closeModal(id, true) && modal.close() },
                { name: 'abort',   style: 'outline-primary', text: getString('cancel'),  fn: (modal: ModalFnParams) => modal.close() }
            ]
        })
        return false
    }
    modals = modals.filter(m => m.id !== id)

    if (!modals || !modals.length) {
        scrollbarUnset()
    }

    return true
}

function handleKeyPress(e) {
    // ESC
    if (e.keyCode === 27) {
        closeTopModal()
    }
}

function closeTopModal() {
    if (!modals || !modals.length) {
        return
    }

    const id = modals[modals.length-1].id
    if (modalRefs[id]) {
        modalRefs[id].close()
    }
}

function scrollbarSet() {
    scrollbarIsSet = true

    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.classList.add('modal-open')
    document.body.style.paddingRight = scrollbarWidth + 'px'
}

function scrollbarUnset() {
    scrollbarIsSet = false

    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
}

function createModal({
    content, id = null, type = null, ...options
}: {
    content: any, id?: string, type?: ModalTypes, [key: string]: any
}) {
    scrollbarSet()

    if (id && isIdInUse(id)) {
        throw new Error(`Modal ID ${id} is already in use!`)
    }

    const newModal = {
        id: id || uuid_v4(),
        type: type || 'html',
        props: {},
        locked: false,
        size: options.size || sizePresets[type] || null,
        content, ...options
    }

    console.debug('[ModalManager] create modal --> ', newModal)

    modals = [...modals, newModal]
}

function lockModal({ id, text }: { id: string, text?: string}) {
    updateModal(id, { locked: text || true })
}

function unlockModal({ id }: { id: string }) {
    updateModal(id, { locked: false })
}

function updateModal(id: string, data: any) {
    console.debug('modals before update -->', modals)
    modals = modals.map(modal => (
        modal.id == id ? {
            ...modal, ...data
        } : modal
    ))
    console.debug('modals after update --> ', modals)
}

function getModal(id: string) {
    return modals.find(modal => modal.id == id)
}

function isIdInUse(id: string) {
    return modals.reduce((lastInUse, modal) => {
        return lastInUse || modal.id == id
    }, false)
}

onMount(() => {
    document.addEventListener('keydown', handleKeyPress)

    events.on(EVENTS.MODAL_OPEN, createModal)
    events.on(EVENTS.MODAL_CLOSE, closeModal)
    events.on(EVENTS.MODAL_LOCK, lockModal)
    events.on(EVENTS.MODAL_UNLOCK, unlockModal)
})

onDestroy(() => {
    document.removeEventListener('keydown', handleKeyPress)
})
</script>

<div id="modal-list">
    {#each modals as modal (modal.id)}
        {#if modal.type === 'component'}
            <Modal data={modal} closeModal={closeModal} events={events} updateRef={updateModalRef}>
                <svelte:component this={modal.content} {...modal.props} />
            </Modal>
        {:else}
            <Modal data={modal} closeModal={closeModal} events={events} updateRef={updateModalRef} />
        {/if}
    {/each}
</div>
