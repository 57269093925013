<script lang="ts">
import { onMount, onDestroy, createEventDispatcher } from 'svelte';
import type { Events, ModalDataFormContent } from '@/components/Modals/types'

const dispatch = createEventDispatcher()

export const events: Events = null
export let data: ModalDataFormContent
const { content, onSubmit } = data

const isHTMLElement = content instanceof HTMLElement

let contentElement
let originalParent

async function handleFormSubmit(e) {
    e.preventDefault()

    // TODO: Parameter überarbeiten und testen

    if (content.action) {
        const response = await fetch(content.action, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: new URLSearchParams([
                [ 'fromajax', '1' ],
                ...new FormData(content).entries(),
            ])
        })

        onSubmit && onSubmit(response)

        if (response.ok) {
            dispatch('closemodal')
        }
    }
}

onMount(() => {
    let formElement

    if (isHTMLElement) {
        originalParent = content.parentNode
        contentElement.appendChild(content)
        formElement = content
    }
    else {
        formElement = contentElement.querySelector('form')
    }

    const cancelButton = contentElement.querySelector('input[type="cancel"],button[type="cancel"]')
    if (cancelButton) {
        cancelButton.addEventListener('click', e => {
            dispatch('closemodal')
        })
    }

    formElement.onsubmit = handleFormSubmit
})

onDestroy(() => {
    if (originalParent) {
        originalParent.appendChild(content)
    }
})

</script>

<div bind:this={contentElement} class="modal-body">
    {#if !isHTMLElement}
        {@html content}
    {/if}
</div>
